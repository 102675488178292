import React from "react";
import loadable from "@loadable/component";

import ReactDOM from "react-dom/client";
import "./index.css";
// ** Redux Imports
import { Provider } from "react-redux";
import { store } from "./redux/storeConfig/store";
const App = loadable(() => import("./App"));
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
